import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import logo from "./avmainlogo-new.png";
import footerlogo from "./avfooter.jpg";
import ArtistList from "./rtsvrf-pages/ArtistList";
import News from "./rtsvrf-pages/News";
import ReactGA from "react-ga4";

const App = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize("G-CWP0L9NG1T", {
      gaOptions: { streamId: "5113855212" },
    });
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
  }, [location]);

  return (
    <div className="App">
      <header className="App-header">
          <table>
                <tr>
                    <td><h1>ArtistVerified Artist News - Viewer App</h1></td>
                    <td><img src={logo} className="App-logo" alt="logo" align="right"/></td>
                </tr>
            </table>
      </header>


      <Routes>
        <Route path="/" element={<ArtistList />} />
        <Route path="news/:id" element={<News />} />
      </Routes>

      <center>
        <img src={footerlogo} className="App-logo" alt="footerlogo" />
        <br />
        Copyright &copy; 2023 ArtistVerified Inc.
      </center>
    </div>
  );
};

export default App;
