import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ total, listPerPage, setCurrentPage }) => {
  return (
    <ReactPaginate
      pageCount={Math.ceil(total / listPerPage)}
      onPageChange={({ selected }) => setCurrentPage(selected + 1)}
      disabledClassName="disabled"
      initialPage={0}
      previousLabel="previous"
      nextLabel="next"
      breakLabel="..."
      breakClassName="break-me"
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      activeLinkClassName="!bg-blue-600 !text-white"
      breakLinkClassName="relative block p-3 leading-tight text-blue-600 bg-white border-slate-300 border border-solid hover:bg-blue-600 hover:text-white"
      containerClassName="flex pl-0 rounded-sm pt-4 list-none justify-start overflow-x-scroll"
      pageLinkClassName="relative block p-3 leading-tight text-blue-600 bg-white border-slate-300 border border-solid hover:bg-blue-600 hover:text-white"
      previousLinkClassName="relative block p-3 leading-tight text-blue-600 bg-white border-slate-300 border border-solid hover:bg-blue-600 hover:text-white"
      nextLinkClassName="relative block p-3 leading-tight text-blue-600 bg-white border-slate-300 border border-solid hover:bg-blue-600 hover:text-white"
    />
  );
};

export default Pagination;
