import React from "react";
import { supabase } from "../rtsvrf-database/SupabaseClient";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const NewsFeedTable = ({ feed, setSelectedArtist }) => {
  let navigate = useNavigate();

  const viewNews = async (artist) => {
    ReactGA.event({
      category: "View News",
      action: "click",
      label: `${
        artist?.artist_news_link
          ? "View Artist"
          : artist?.artist_genre
          ? "View Genre"
          : "View Industry"
      }`,
    });

    if (artist?.artist_news_link) {
      navigate(`/news/${artist.rtsvrf_artist_id}`);
    } else if (artist?.artist_genre) {
      const genreResult = await supabase
        .from("tbl_staged_genre_news")
        .select()
        .eq("news_genre", artist.artist_genre)
        .single();
      const genre = genreResult.data;
      if (genre) {
        navigate(`/news/${genre.id}`);
      } else {
        navigate(`/news/Industry`);
      }
    } else {
      navigate(`/news/Industry`);
    }
    setSelectedArtist(artist);
  };

  if (!feed) {
    return <div>Loading...</div>;
  }
  if (feed.length === 0) {
    return <div>No data found</div>;
  }
  return (
    <div className="my-4 overflow-x-auto">
      <table className="w-full border border-solid border-gray rounded-lg divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="sm:px-1 px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
              Artist
            </th>
            <th className="sm:px-1 px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
              Genre
            </th>
            <th className="sm:px-1 px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
              News
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {feed.map((item) => (
            <tr key={item.rtsvrf_artist_id}>
              <td className="sm:px-1 px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                {item.artist_name}
              </td>
              <td className="sm:px-1 px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                {item.artist_genre}
              </td>
              <td className="sm:px-1 px-6 py-4 text-sm font-medium cursor-pointer text-blue-600 whitespace-nowrap hover:underline">
                <span onClick={() => viewNews(item)}>
                  {item?.artist_news_link
                    ? "View Artist"
                    : item?.artist_genre
                    ? "View Genre"
                    : "View Industry"}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NewsFeedTable;
