import React, { useState, useEffect } from "react";
import RssParser from "react-native-rss-parser";
import {
  ARTIST_NEWS_FEED_TYPE,
  GENRE_NEWS_FEED_TYPE,
} from "../rtsvrf-utils/Constants";
import { useNavigate } from "react-router-dom";
import google from "../rtsvrf-assets/play_store.png";
import appleStore from "../rtsvrf-assets/apple_store.svg";

const NewsFeed = ({ newsLink, selectedGenre, selectedArtist }) => {
  const [feed, setFeed] = useState(null);
  const [slug, setSlug] = useState(null);
  let navigate = useNavigate();
  useEffect(() => {
    const fetchFeed = async () => {
      const xmlLink = newsLink?.split("https://rss.app/feeds/")?.[1];
      setSlug(xmlLink?.split(".xml")?.[0]);
      const response = await fetch(newsLink);
      const text = await response.text();
      const parsed = await RssParser.parse(text);
      setFeed(parsed);
    };

    fetchFeed();
  }, [newsLink]);

  const goBack = () => {
    if (window.location.pathname.includes("news/")) {
      navigate("/");
    }
  };

  if (!feed || !newsLink) {
    return <div>Loading feed ...</div>;
  }

  return (
    <div>
      <nav className="w-full flex justify-between items-center rounded-md">
        <ol className="list-reset flex">
          <li key={1} className="cursor-pointer" onClick={() => goBack()}>
            <p className="text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600">
              App
            </p>
          </li>
          <li key={2}>
            <span className="mx-2 text-neutral-500 dark:text-neutral-400">
              /
            </span>
          </li>
          <li key={3} className="text-neutral-500 dark:text-neutral-400">
            News
          </li>
          <li key={4}>
            <span className="mx-2 text-neutral-500 dark:text-neutral-400">
              /
            </span>
          </li>
          <li key={5} className="text-neutral-500 dark:text-neutral-400">
            {selectedArtist?.artist_news_link
              ? selectedArtist?.artist_name
              : selectedGenre?.news_genre}
          </li>
        </ol>
        <div>
          <img
            className="cursor-pointer"
            width={"120"}
            height={"60"}
            src={appleStore}
            alt="apple"
          />
          <img
            className="mt-2 cursor-pointer"
            width={"120"}
            height={"60"}
            src={google}
            alt="google"
          />
        </div>
      </nav>
      {!selectedArtist?.artist_news_link && selectedArtist?.artist_name ? (
        <div
          class="bg-blue-100 my-4 border-t border-b border-blue-500 text-blue-700 px-4 py-3"
          role="alert"
        >
          {selectedGenre?.news_genre === "Industry" ? (
            <p class="text-sm">{`Oops sorry! We don't have artist news for ${
              selectedArtist?.artist_name
            } yet, and we don't have their matching ${
              selectedArtist?.artist_genre || ""
            } genre news either, so we're showing you industry news instead. We've also let a human know so they can fix this.`}</p>
          ) : (
            <p class="text-sm">{`Oops sorry! We don't have artist news for ${selectedArtist?.artist_name} yet, so we're showing you ${selectedArtist?.artist_genre} genre news instead. We've also let a human know so they can fix this.`}</p>
          )}
        </div>
      ) : null}
      {slug && (
        <div className="py-6">
          <iframe
            width="900"
            height={"600"}
            className="rounded-md lg:w-full"
            title="artist feed"
            src={`https://rss.app/embed/v1/${
              selectedArtist?.artist_news_link
                ? ARTIST_NEWS_FEED_TYPE
                : GENRE_NEWS_FEED_TYPE
            }/${slug}`}
            frameborder="0"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default NewsFeed;
