import React, { useState, useEffect } from "react";
import { supabase } from "../rtsvrf-database/SupabaseClient";
import NewsFilter from "../rtsvrf-components/NewsFilter";
import NewsFeedTable from "../rtsvrf-components/NewsFeedTable";
import Pagination from "../rtsvrf-components/Pagination";
import { INDUSTRY_GENRE } from "../rtsvrf-utils/Constants";

const ArtistList = () => {
  const [feed, setFeed] = useState(null);
  const [genreList, setGenreList] = useState(null);
  const [total, setTotal] = useState(0);
  const [filteredFeed, setfilteredFeed] = useState(null);
  const [filter, setFilter] = useState("");
  const [genreFilter, setGenreFilter] = useState("");
  const [feedFilter, setFeedFilter] = useState("");
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const listPerPage = 10;

  useEffect(() => {
    const getData = async () => {
      let results = await supabase
        .from("tbl_locked_artist_ids_stub_v2")
        .select(
          `rtsvrf_artist_id, artist_name, artist_spotfy_id, artist_news_link, artist_genre`
        )
        .order("artist_name", { ascending: true });
      setFeed(results.data);
      setTotal(results.data.length);
    };
    getData();
  }, []);

  useEffect(() => {
    const getGenreData = async () => {
      let results = await supabase.from("tbl_staged_genre_news").select();
      const genres = results.data?.filter(
        (genre) => genre.news_genre !== INDUSTRY_GENRE
      );
      setGenreList(genres);
    };
    getGenreData();
  }, []);

  useEffect(() => {
    async function fetchPaginationData() {
      let data = feed;
      if (feedFilter === "Feed") {
        data = data?.filter((item) => item.artist_news_link);
      } else if (feedFilter === "No feed") {
        data = data?.filter((item) => !item.artist_news_link);
      }
      if (genreFilter) {
        data = data?.filter((item) =>
          item?.artist_genre?.toLowerCase().includes(genreFilter.toLowerCase())
        );
      }
      if (filter) {
        data = data?.filter((item) =>
          item?.artist_name?.toLowerCase().includes(filter.toLowerCase())
        );
      }
      setTotal(data?.length);
      data = data?.slice(
        (currentPage - 1) * listPerPage,
        currentPage * listPerPage
      );
      setfilteredFeed(data);
    }
    fetchPaginationData();
  }, [currentPage, feed, filter, feedFilter, genreFilter]);

  useEffect(() => {
    setFilter("");
    setFeedFilter("");
    setGenreFilter("");
  }, [selectedArtist]);

  return (
    <div className="sm:m-2 m-4">
      <NewsFilter
        feed={feed}
        genreList={genreList}
        setFilter={setFilter}
        feedFilter={feedFilter}
        setFeedFilter={setFeedFilter}
        genreFilter={genreFilter}
        setGenreFilter={setGenreFilter}
        setCurrentPage={setCurrentPage}
      />
      <NewsFeedTable
        setSelectedArtist={setSelectedArtist}
        feed={filteredFeed}
      />
      <Pagination
        listPerPage={listPerPage}
        total={total}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default ArtistList;
