import React, { useState, useEffect } from "react";
import { supabase } from "../rtsvrf-database/SupabaseClient";
import NewsFeed from "../rtsvrf-components/NewsFeed";
import { useParams } from "react-router-dom";
import { INDUSTRY_GENRE } from "../rtsvrf-utils/Constants";

const News = () => {
  const [newsLink, setNewsLink] = useState(null);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const getGenreNews = async () => {
      const genreResult = await supabase
        .from("tbl_staged_genre_news")
        .select()
        .eq("id", id)
        .single();
      const genre = genreResult.data;
      if (genre) {
        setSelectedGenre(genre);
        setNewsLink(genre?.news_genre_link);
      } else {
        await getIndustryNews();
      }
    };

    const getIndustryNews = async () => {
      const industryResult = await supabase
        .from("tbl_staged_genre_news")
        .select()
        .eq("news_genre", "Industry")
        .single();
      const industry = industryResult.data;
      if (industry) {
        setSelectedGenre(industry);
        setNewsLink(industry?.news_genre_link);
      }
    };

    const getArtistData = async () => {
      let artistResult = await supabase
        .from("tbl_locked_artist_ids_stub_v2")
        .select(
          `rtsvrf_artist_id, artist_name, artist_spotfy_id, artist_news_link, artist_genre`
        )
        .eq("rtsvrf_artist_id", id)
        .single();
      const artist = artistResult.data;
      if (artist) {
        setSelectedArtist(artist);
        if (artist?.artist_news_link) {
          setNewsLink(artist.artist_news_link);
        } else {
          const genreResult = await supabase
            .from("tbl_staged_genre_news")
            .select()
            .eq("news_genre", artist?.artist_genre || INDUSTRY_GENRE)
            .single();
          const genre = genreResult.data;
          if (genre) {
            setSelectedGenre(genre);
            setNewsLink(genre?.news_genre_link);
          } else {
            await getIndustryNews();
          }
        }
      } else {
        await getGenreNews();
      }
    };
    getArtistData();
  }, [id]);

  return (
    <div className="sm:m-2 m-4">
      <NewsFeed
        selectedArtist={selectedArtist}
        selectedGenre={selectedGenre}
        newsLink={newsLink}
      />
    </div>
  );
};

export default News;
