import React, { useEffect, useState } from "react";

const NewsFilter = ({
  feed,
  genreList,
  setFilter,
  setCurrentPage,
  feedFilter,
  setFeedFilter,
  setGenreFilter,
}) => {
  const [showArtistFilter, setShowArtistFilter] = useState(false);
  const [artistSearchInput, setArtistSearchInput] = useState("");
  const [showGenreFilter, setShowGenreFilter] = useState(false);
  const [genreSearchInput, setGenreSearchInput] = useState("");
  const [filteredArtists, setFilteredArtists] = useState(null);
  const [filteredGenre, setFilteredGenre] = useState(null);

  useEffect(() => {
    setFilteredArtists(feed);
  }, [feed]);

  useEffect(() => {
    setFilteredGenre(genreList);
  }, [genreList]);

  const filterArtistList = (searchTerm, key) => {
    setArtistSearchInput(searchTerm);
    if (!searchTerm) {
      setFilter("");
      setCurrentPage(1);
      setFilteredArtists(feed);
      return;
    }
    const filtered = feed.filter((item) => {
      return item?.artist_name
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());
    });

    setFilteredArtists(filtered);
    if (key === "Enter") {
      setFilter(searchTerm);
      setCurrentPage(1);
    }
  };

  const handleArtistSelection = (selectedItem) => {
    setArtistSearchInput(selectedItem.artist_name);
    setFilter(selectedItem.artist_name);
    setCurrentPage(1);
    setShowArtistFilter(false);
  };

  const clearArtistSelection = (e) => {
    e.preventDefault();
    setArtistSearchInput("");
    setFilter("");
    setCurrentPage(1);
    setShowArtistFilter(false);
  };

  const filterGenreList = (searchTerm, key) => {
    setGenreSearchInput(searchTerm);
    if (!searchTerm) {
      setGenreFilter("");
      setCurrentPage(1);
      setFilteredGenre(genreList);
      return;
    }
    const filtered = genreList.filter((item) => {
      return item?.news_genre?.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setFilteredGenre(filtered);
    if (key === "Enter") {
      setGenreFilter(searchTerm);
      setCurrentPage(1);
    }
  };

  const handleGenreSelection = (selectedItem) => {
    setGenreSearchInput(selectedItem.news_genre);
    setGenreFilter(selectedItem.news_genre);
    setCurrentPage(1);
    setShowGenreFilter(false);
  };

  const clearGenreSelection = (e) => {
    e.preventDefault();
    setGenreSearchInput("");
    setGenreFilter("");
    setCurrentPage(1);
    setShowGenreFilter(false);
  };

  const handleArtistFeedFilter = (value) => {
    setFeedFilter(value);
    setCurrentPage(1);
  };

  if (!feed || !filteredArtists || !genreList) {
    return <div>Loading feed ...</div>;
  }

  return (
    <div className="sm:flex sm: flex-col sm: items-center sm: justify-center">
      <div className="relative inline-block	my-1 mr-2">
        <button
          className="bg-white rounded-md p-1 w-48 text-left text-sm cursor-pointer border-solid border border-black"
          onClick={() => setShowArtistFilter(!showArtistFilter)}
        >
          {artistSearchInput ? (
            <span className="w-full flex items-center">
              {artistSearchInput}{" "}
              <p
                onClick={(e) => clearArtistSelection(e)}
                className="ml-auto font-bold"
              >
                X
              </p>
            </span>
          ) : (
            "Search Artist"
          )}
        </button>

        <div
          className={`absolute bg-white min-w-230 overflow-y-scroll max-h-80 z-1 border border-grey rounded-md ${
            showArtistFilter ? "block" : "hidden"
          }`}
        >
          <input
            type="text"
            defaultValue={artistSearchInput}
            className="box-border p-2 text-base border-b"
            onKeyUp={(e) => filterArtistList(e.target.value, e.key)}
            placeholder="Search.."
          />
          {filteredArtists.slice(0, 20).map((item) => (
            <h5
              onClick={() => handleArtistSelection(item)}
              className="p-1 text-base bg-gray-100 hover:bg-gray-400 cursor-pointer"
              key={item.rtsvrf_artist_id}
            >
              {item.artist_name}
            </h5>
          ))}
        </div>
      </div>
      <div className="relative inline-block	my-1 mr-2">
        <button
          className="bg-white rounded-md p-1 w-48 text-left text-sm cursor-pointer border-solid border border-black"
          onClick={() => setShowGenreFilter(!showGenreFilter)}
        >
          {genreSearchInput ? (
            <span className="w-full flex items-center">
              {genreSearchInput}{" "}
              <p
                onClick={(e) => clearGenreSelection(e)}
                className="ml-auto font-bold"
              >
                X
              </p>
            </span>
          ) : (
            "Search Genre"
          )}
        </button>
        <div
          className={`absolute left-1 bg-white min-w-230 overflow-y-scroll max-h-80 z-1 border border-grey rounded-md ${
            showGenreFilter ? "block" : "hidden"
          }`}
        >
          <input
            type="text"
            defaultValue={genreSearchInput}
            className="box-border p-2 text-base border-b"
            onKeyUp={(e) => filterGenreList(e.target.value, e.key)}
            placeholder="Search.."
          />
          {filteredGenre?.map((item) => (
            <h5
              onClick={() => handleGenreSelection(item)}
              className="p-1 text-base bg-gray-100 hover:bg-gray-400 cursor-pointer"
              key={item.news_genre}
            >
              {item.news_genre}
            </h5>
          ))}
        </div>
      </div>
      <div className="relative inline-block	my-1 mr-2">
        <select
          defaultValue={feedFilter}
          onChange={(e) => handleArtistFeedFilter(e.target.value)}
          className="bg-white rounded-md p-[5px] w-48 text-left text-sm cursor-pointer border-solid border border-black"
        >
          <option value={""}>All</option>
          <option value={"Feed"}>Select artists with feeds</option>
          <option value={"No feed"}>Select artists with no feeds</option>
        </select>
      </div>
    </div>
  );
};

export default NewsFilter;
